import { useState } from "react";
import { Button } from "../button";
import { SpriteIcon } from "../icon";
import ClickAwayListener from "react-click-away-listener";
import clsx from "clsx";

interface Props {
  optionList: string[];
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

export const DropDown = ({
  optionList,
  selectedOption,
  setSelectedOption,
}: Props) => {
  const [isDropDown, setIsDropDown] = useState(false);

  const handleDropDownClick = () => {
    if (!isDropDown) setIsDropDown(true);
    else setIsDropDown(false);
  };

  return (
    <div className="relative w-full">
      <ClickAwayListener onClickAway={() => setIsDropDown(false)}>
        <Button
          onClick={handleDropDownClick}
          className={clsx(
            "flex justify-between w-full gap-8 px-8 py-10 font-medium rounded-[4px] border-1 text-14",
            isDropDown && "border-blue-500"
          )}
        >
          <span>{selectedOption}</span>
          <SpriteIcon
            size={14}
            icon="chevron-down"
            className={clsx("transition-transform", isDropDown && "rotate-180")}
          />
        </Button>
      </ClickAwayListener>
      {isDropDown && (
        <div className="z-[100] absolute left-0 right-0 bg-white top-40 border-1 border-blue-500 rounded-[4px]">
          <ul className="flex flex-col text-left">
            {optionList.map((option, idx) => {
              return (
                <li
                  key={idx}
                  className="px-8 py-10 font-medium cursor-pointer hover:bg-blue-500 hover:text-white text-14"
                  onClick={() => setSelectedOption(option)}
                >
                  {option}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
