import { type DUMMY_PROBLEM_DETAIL } from "dummy-data";

interface Props {
  contentExample: (typeof DUMMY_PROBLEM_DETAIL)["examples"];
}

const DetailContentExample = ({ contentExample }: Props) => {
  // 예시 입출력값 2줄 (line break) 이상이면 이면 data 어떻게 받아올지 상의 필요

  return (
    <div className="flex gap-40">
      <article className="flex flex-col w-full">
        <h2 className="pb-12 font-medium border-b-2 text-22">예제 입력</h2>
        <ul className="flex flex-col gap-20 py-20">
          {contentExample.map(({ input }, index) => {
            return (
              <li key={index} className="flex flex-col gap-8 p-16 bg-gray-200">
                {input.split("\n").map((line, idx) => {
                  return (
                    <span
                      key={idx}
                      className="italic font-medium text-blue-600"
                    >
                      {line}
                    </span>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </article>
      <article className="flex flex-col w-full">
        <h2 className="pb-12 font-medium border-b-2 text-22">예제 출력</h2>
        <ul className="flex flex-col gap-20 py-20">
          {contentExample.map(({ output }, index) => {
            return (
              <li key={index} className="flex flex-col gap-8 p-16 bg-gray-200">
                {output.split("\n").map((line, idx) => {
                  return (
                    <span
                      key={idx}
                      className="italic font-medium text-blue-600"
                    >
                      {line}
                    </span>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </article>
    </div>
  );
};

export default DetailContentExample;
