import { useState } from "react";
import { Button } from "../button";
import { SpriteIcon } from "../icon";
import { useSearchParams } from "react-router-dom";

export const SearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchVal, setSearchVal] = useState(searchParams.get("search") ?? "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchVal(value);
  };

  const onSearchClearClick = () => {
    setSearchVal("");
  };

  const onSearchButtonClick = () => {
    setSearchParams((param) => {
      if (!searchVal) param.delete("search");
      else param.set("search", searchVal);

      return param;
    });
  };

  return (
    <div className="relative flex items-center">
      <input
        type="text"
        value={searchVal}
        onChange={handleInputChange}
        className="outline-blue-500 w-full pl-12 pr-70 h-44 border-1 rounded-[4px]"
      />
      <div className="absolute gap-12 right-12 flex-center">
        {searchVal && (
          <Button onClick={onSearchClearClick}>
            <SpriteIcon icon="search-clear" size={16} fill="#a9a9a9" />
          </Button>
        )}
        <Button onClick={onSearchButtonClick}>
          <SpriteIcon icon="search-icon" size={20} />
        </Button>
      </div>
    </div>
  );
};
