export const LANGUAGE_OPTION_LIST = {
  "모든 언어": -1,
  C: 1,
  "C++": 2,
  "C#": 3,
  Java: 4,
  Kotlin: 5,
  Go: 6,
  JavaScript: 7,
  TypeScript: 8,
} as const;

export type LanguageOptionType = keyof typeof LANGUAGE_OPTION_LIST;
export type LanguageOptionValueType =
  (typeof LANGUAGE_OPTION_LIST)[LanguageOptionType];

export const RESULT_OPTION_LIST = {
  "모든 결과": -1,
  성공: 1,
  실패: 2,
  "런타임 에러": 3,
  "컴파일 에러": 4,
} as const;

export type ResultOptionType = keyof typeof RESULT_OPTION_LIST;
export type ResultOptionValueType =
  (typeof RESULT_OPTION_LIST)[ResultOptionType];

export const SHARE_OPTION_LIST = {
  공개: 1,
  비공개: 2,
  "성공 시 공개": 3,
} as const;

export type ShareOptionType = keyof typeof SHARE_OPTION_LIST;
export type ShareOptionValueType = (typeof SHARE_OPTION_LIST)[ShareOptionType];
