import { DropDown } from "components/shared";
import { useFormContext } from "react-hook-form";
import { type ProblemSubmitFormType } from "..";
import { SHARE_OPTION_LIST } from "variables/problem";

const SubmitShareSource = () => {
  const { watch, setValue } = useFormContext<ProblemSubmitFormType>();

  return (
    <div className="flex flex-col w-full gap-10 select-none max-w-140">
      <label className="font-medium text-15">공개 선택</label>
      <DropDown
        optionList={Object.keys(SHARE_OPTION_LIST)}
        selectedOption={watch("share")}
        setSelectedOption={(option) => setValue("share", option)}
      />
    </div>
  );
};

export default SubmitShareSource;
