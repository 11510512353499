import { type DUMMY_SUBMIT_HISTORY } from "dummy-data";
import { LANGUAGE_OPTION_LIST, RESULT_OPTION_LIST } from "variables/problem";
import clsx from "clsx";
import { Link } from "react-router-dom";

interface Props {
  submitHistoryInfo: typeof DUMMY_SUBMIT_HISTORY;
}

const HistoryTable = ({ submitHistoryInfo }: Props) => {
  const getKeyByValue = (object: Record<string, number>, value: number) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  return (
    <div className="rounded-[4px] border-1">
      <table className="w-full">
        <thead className="font-medium border-b">
          <tr className="under:px-12 text-14 under:text-[#446879] text-left h-48">
            <th className="w-[10%]">제출 번호</th>
            <th className="w-[12.5%]">아이디</th>
            <th className="w-[12.5%]">결과</th>
            <th className="w-[12.5%]">메모리</th>
            <th className="w-[12.5%]">시간</th>
            <th className="w-[12.5%]">코드 길이</th>
            <th className="w-[12.5%]">언어</th>
            <th className="w-[10%]">제출한 시간</th>
            <th className="w-[5%] !text-center">소스 보기</th>
          </tr>
        </thead>
        <tbody>
          {submitHistoryInfo.map(
            ({
              id,
              userName,
              resultId,
              memory,
              time,
              languageId,
              codeLength,
              ago,
            }) => {
              return (
                <tr
                  key={id}
                  className="h-48 font-medium text-left border-b last:border-none under:px-12 text-14"
                >
                  <td>{id}</td>
                  <td>
                    <Link to="#">{userName}</Link>
                  </td>
                  <td
                    className={clsx(
                      "font-semibold",
                      resultId === 1 && "text-green-600",
                      resultId === 2 && "text-red-600",
                      resultId >= 3 && "text-purple-600"
                    )}
                  >
                    {getKeyByValue(RESULT_OPTION_LIST, resultId)}
                  </td>
                  <td>{memory} KB</td>
                  <td>{time} ms</td>
                  <td>{codeLength} B</td>
                  <td>{getKeyByValue(LANGUAGE_OPTION_LIST, languageId)}</td>
                  <td>{ago}분 전</td>
                  <td className="text-center">
                    <Link
                      to={"#"}
                      className="px-8 py-6 text-white bg-blue-500 rounded-sm text-13"
                    >
                      Source
                    </Link>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HistoryTable;
