import { Link } from "react-router-dom";

const NavList = () => {
  return (
    <nav className="w-full px-40">
      <ul className="flex gap-28">
        {NAV_LIST.map(({ name, path }, idx) => {
          return (
            <li key={idx} className="font-medium text-16">
              <Link to={path}>{name}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavList;

const NAV_LIST = [
  {
    name: "알고리즘",
    path: "/problem",
  },
  {
    name: "자유게시판",
    path: "/forum",
  },
  {
    name: "문의",
    path: "/cs",
  },
];
