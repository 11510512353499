export const PROBLEM_KEYS = {
  all: ["problem"] as const,
  lists: () => [...PROBLEM_KEYS.all, "list"] as const,
  list: (pageNum: number, filters: string) =>
    [...PROBLEM_KEYS.lists(), { filters, pageNum }] as const,
  submit: () => [...PROBLEM_KEYS.all, "submit"] as const,
};

export const FORUM_KEYS = {
  all: ["forum"] as const,
  lists: () => [...FORUM_KEYS.all, "list"] as const,
  list: (pageNum: number, filters: string) =>
    [...FORUM_KEYS.lists(), { filters, pageNum }] as const,
  submit: () => [...FORUM_KEYS.all, "submit"] as const,
};
