import { API } from "api/index";
import { PROBLEM_KEYS } from "constant";
import { UseQueryOptions, useQuery } from "react-query";

export const useProblemList = (
  pageIndex: number,
  filters: ProblemListFilters,
  option: Omit<UseQueryOptions<Pagination<ProblemPagnationItem>>, "queryKey">
) =>
  useQuery(
    PROBLEM_KEYS.list(pageIndex, filters.toString()),
    () => API.problemService.problemList(pageIndex, filters),
    option
  );
