import DetailContentExplain from "./explain";
import DetailContentExample from "./example";
import { type DUMMY_PROBLEM_DETAIL } from "dummy-data";

interface Props {
  problemInfo: typeof DUMMY_PROBLEM_DETAIL;
}

export const ProblemDetailInfo = ({ problemInfo }: Props) => {
  return (
    <section className="flex flex-col gap-40 px-20 mx-auto mt-40 max-w-1200">
      <h1 className="font-medium text-28">{problemInfo.title}</h1>
      <DetailContentExplain contentExplain={problemInfo.explain} />
      <DetailContentExample contentExample={problemInfo.examples} />
    </section>
  );
};
