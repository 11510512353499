import { ProblemContent } from "components/problem";
import { type QueryClient } from "react-query";
import { API } from "api";
import {
  PROBLEM_SORT_CONVERTER,
  PROBLEM_KEYS,
  ProblemSortType,
} from "constant";
import { useLoaderData } from "react-router-dom";

export type ProblemLoaderType = {
  initialData: Pagination<ProblemPagnationItem>;
};

export const loader = async (request: Request, queryClient: QueryClient) => {
  const pageParam =
    Number(new URL(request.url).searchParams.get("page") ?? "1") - 1;
  const sortParam = (new URL(request.url).searchParams.get("sort") ??
    "recent") as ProblemSortType;
  const levelParam = new URL(request.url).searchParams.get("level");
  const searchParam = new URL(request.url).searchParams.get("search");

  const filters = {
    sortBy: PROBLEM_SORT_CONVERTER[sortParam]["sortBy"],
    direction: PROBLEM_SORT_CONVERTER[sortParam]["direction"],
  };

  const initialData = await queryClient.fetchQuery(
    PROBLEM_KEYS.list(pageParam, filters.toString()),
    () => API.problemService.problemList(pageParam, filters)
  );

  return { initialData };
};

const Problem = () => {
  const { initialData } = useLoaderData() as ProblemLoaderType;

  return (
    <div>
      <ProblemContent initialData={initialData} />
    </div>
  );
};

export default Problem;
