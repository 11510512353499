import { Button } from "../button";
import { SpriteIcon } from "../icon";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import usePagination from "react-use-pagination-hook";
import clsx from "clsx";

interface Props {
  totalPage: number;
  pageParam: number;
}

export const Pagination = ({ totalPage, pageParam }: Props) => {
  const [_, setSearchParams] = useSearchParams();

  const {
    pageList,
    currentPage,
    setPage,
    goNext,
    goBefore,
    goFirstSection,
    goLastSection,
  } = usePagination({
    numOfPage: 5,
    totalPage,
    initialPage: pageParam,
    onPageChange: (page) => {
      setSearchParams((params) => {
        params.set("page", page.toString());

        return params;
      });
    },
  });

  useEffect(() => {
    setSearchParams((params) => {
      if (pageParam <= 0) params.set("page", "1");
      else if (pageParam > totalPage) params.set("page", totalPage.toString());

      return params;
    });
  }, [pageParam]);

  return (
    <div className="flex gap-4 mx-auto rounded-sm bg-blue-50">
      <Button
        className="rounded-md w-30 h-30 flex-center hover:bg-blue-400 group"
        onClick={goFirstSection}
      >
        <SpriteIcon
          size={12}
          icon="backward-step"
          className="group-hover:stroke-white"
        />
      </Button>
      <Button
        className="rounded-md w-30 h-30 flex-center hover:bg-blue-400 group"
        onClick={goBefore}
      >
        <SpriteIcon
          size={20}
          icon="chevron-left"
          className="group-hover:stroke-white"
        />
      </Button>
      <div className="flex">
        {pageList.map((page) => {
          return (
            <Button
              key={page}
              className={clsx(
                "rounded-md cursor-pointer w-30 h-30 flex-center hover:bg-blue-400 group",
                currentPage === page ? "bg-blue-400 under:text-white" : ""
              )}
              onClick={() => setPage(page)}
            >
              <span className="font-medium text-14 group-hover:text-white">
                {page}
              </span>
            </Button>
          );
        })}
      </div>
      <Button
        className="rounded-md w-30 h-30 flex-center hover:bg-blue-400 group"
        onClick={goNext}
      >
        <SpriteIcon
          size={20}
          icon="chevron-right"
          className="group-hover:stroke-white"
        />
      </Button>
      <Button
        className="rounded-md w-30 h-30 flex-center hover:bg-blue-400 group"
        onClick={goLastSection}
      >
        <SpriteIcon
          size={12}
          icon="forward-step"
          className="group-hover:stroke-white"
        />
      </Button>
    </div>
  );
};
