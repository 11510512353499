import { problemServiceRemote } from "./service";

const provideAPIService = () => {
  const problemService = problemServiceRemote();

  return {
    problemService,
  };
};

export const API = provideAPIService();
