import Header from "./header";
import Footer from "./footer";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Suspense } from "react";

const Layout = () => {
  return (
    <div>
      <Header />
      <main>
        <Suspense>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <ScrollRestoration />
    </div>
  );
};

export default Layout;
