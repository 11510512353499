import { Button } from "components/shared";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";

export const LevelSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onLevelButtonClick = (lvl: string) => {
    const paramValues = searchParams.get("level")?.split(",");
    const hasDuplicateValues = paramValues?.includes(lvl);

    setSearchParams((param) => {
      if (paramValues && hasDuplicateValues) {
        if (paramValues.length === 1) {
          param.delete("level");
        } else if (paramValues.length > 1) {
          param.set("level", paramValues.filter((v) => v !== lvl).join(","));
        }
      } else {
        param.set("level", !paramValues ? lvl : `${param.get("level")},${lvl}`);
      }

      return param;
    });
  };

  return (
    <div>
      <ul className="flex items-center h-full gap-8">
        {LEVELS.map(({ lvl }, idx) => {
          return (
            <li
              key={idx}
              className={clsx(
                "rounded-[4px] border-1",
                searchParams.get("level")?.split(",").includes(lvl)
                  ? "bg-blue-500 under:text-white"
                  : " under:text-gray-500"
              )}
            >
              <Button
                className="p-12 font-semibold"
                onClick={() => onLevelButtonClick(lvl)}
              >
                Lv. {lvl}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const LEVELS = [
  {
    lvl: "1",
  },
  {
    lvl: "2",
  },
  {
    lvl: "3",
  },
];
