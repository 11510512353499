const Footer = () => {
  return (
    <footer className="bg-gray-300 mt-120">
      <div className="flex flex-col px-20 py-40 mx-auto max-w-1200">
        <ul className="flex flex-col gap-8 font-medium text-14">
          <li>사업자 등록번호: 123-456-789</li>
          <li>공동대표: 이소망, 이영현</li>
          <li>주소: 퐁퐁시티</li>
          <li>이메일: admin@codestack.io</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
