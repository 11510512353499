import { Link } from "react-router-dom";

const LoginBtn = () => {
  return (
    <div className="shrink-0">
      <Link
        to={"/signin"}
        className="block py-8 px-16 bg-blue-500 rounded-[2px]"
      >
        <p className="font-medium text-white text-14">로그인</p>
      </Link>
    </div>
  );
};

export default LoginBtn;
