import { useFormContext, Controller } from "react-hook-form";
import { SubmitHistoryFormType } from "..";

const HistoryUserSearch = () => {
  const { control } = useFormContext<SubmitHistoryFormType>();

  return (
    <Controller
      name="user"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <input
            type="text"
            value={value}
            className="rounded-[4px] border-1 w-150 px-8 text-14"
            placeholder="아이디"
            onChange={(e) => onChange(e.target.value)}
          />
        );
      }}
    />
  );
};

export default HistoryUserSearch;
