export const PROBLEM_SORT_CONVERTER = {
  recent: {
    sortBy: "id",
    direction: "desc",
  },
  past: {
    sortBy: "id",
    direction: "asc",
  },
  clear_desc: {
    sortBy: "acceptedRate",
    direction: "desc",
  },
  clear_asc: {
    sortBy: "acceptedRate",
    direction: "asc",
  },
} as const;

export type ProblemSortType = keyof typeof PROBLEM_SORT_CONVERTER;
export type ProblemSortValueType =
  (typeof PROBLEM_SORT_CONVERTER)[ProblemSortType];
