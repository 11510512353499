import { MainBanner, MainCategories } from "components/main";

const Main = () => {
  return (
    <div>
      <MainBanner />
      <MainCategories />
    </div>
  );
};

export default Main;
