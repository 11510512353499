import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { Button } from "components/shared";

export const ProblemDetailNav = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onNavClick = (query: string) => {
    setSearchParams((param) => {
      if (query === "default") param.delete("nav");
      else param.set("nav", query);

      return param;
    });
  };

  return (
    <nav className="px-20 mx-auto select-none mt-60 max-w-1200">
      <ul className="flex gap-6">
        {NAV_LIST.map(({ name, query }, idx) => {
          return (
            <li
              key={idx}
              className={clsx(
                "rounded-[4px] border-1 text-14",
                searchParams.get("nav") === query &&
                  "bg-blue-500 under:text-white",
                !searchParams.get("nav") &&
                  query === "default" &&
                  "bg-blue-500 under:text-white"
              )}
            >
              <Button
                className="p-12 font-medium"
                onClick={() => onNavClick(query)}
              >
                {name}
              </Button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const NAV_LIST = [
  {
    name: "문제 설명",
    query: "default",
  },
  {
    name: "제출",
    query: "submit",
  },
  {
    name: "내 제출",
    query: "my_submits",
  },
  {
    name: "모든 채점",
    query: "all_submits",
  },
];
