import { Link } from "react-router-dom";
import NavList from "./nav-list";
import LoginBtn from "./login-btn";

const Header = () => {
  return (
    <header className="mx-auto max-w-1200">
      <div className="flex items-center w-full h-64 px-20">
        <h1 className="shrink-0">
          <Link to="/" className="gap-6 flex-center">
            <img
              src="/assets/shared/cs-logo-3.png"
              width={160}
              className="w-160"
              alt="code stack"
            />
          </Link>
        </h1>
        <NavList />
        <LoginBtn />
      </div>
    </header>
  );
};

export default Header;
