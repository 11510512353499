import { Pagination, ProblemTable } from "components/shared";
import { useProblemList } from "hooks/problem";
import { DUMMY_PROBLEM_ITEMS } from "dummy-data";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PROBLEM_SORT_CONVERTER, ProblemSortType } from "constant";
import { type ProblemLoaderType } from "pages/problem";
import ContentMenu from "./menu";

export const ProblemContent = ({ initialData }: ProblemLoaderType) => {
  const [totalPage, setTotalPage] = useState(initialData.totalPage);
  const [totalCount, setTotalCount] = useState(initialData.totalCount);
  const [searchParams] = useSearchParams();

  const pageParam = Number(searchParams.get("page") ?? "1");
  const levelParam = searchParams
    .get("level")
    ?.split(",")
    .map((v) => Number(v)) ?? [0];
  const searchParam = searchParams.get("search") ?? "";
  const sortParam = (searchParams.get("sort") ?? "recent") as ProblemSortType;

  const { data: problemList, refetch } = useProblemList(
    pageParam - 1,
    {
      // level: levelParam,
      // search: searchParam,
      sortBy: PROBLEM_SORT_CONVERTER[sortParam]["sortBy"],
      direction: PROBLEM_SORT_CONVERTER[sortParam]["direction"],
    },
    {
      initialData,
      onSuccess({ totalPage, totalCount }) {
        setTotalPage(totalPage);
        setTotalCount(totalCount);
      },
    }
  );

  return (
    <section className="flex flex-col gap-16 px-20 mx-auto mt-80 max-w-1200">
      <ContentMenu totalCount={totalCount} />
      <ProblemTable ProblemItems={problemList?.items ?? DUMMY_PROBLEM_ITEMS} />
      <Pagination totalPage={totalPage} pageParam={pageParam} />
    </section>
  );
};
