import { type ComponentProps } from "react";

interface Props extends ComponentProps<"svg"> {
  icon: string;
  size: number;
}

export const SpriteIcon = ({
  icon,
  size,
  color = "black",
  ...props
}: Props) => {
  return (
    <svg {...props} stroke={color} width={size} height={size}>
      <use href={`/assets/icons/sprite.svg#${icon}`} />
    </svg>
  );
};
