import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LANGUAGE_OPTION_LIST, LanguageOptionType } from "variables/problem";
import { DUMMY_SUBMIT_HISTORY } from "dummy-data";
import { Button } from "components/shared";
import HistoryLanguageSelector from "./language-selector";
import HistoryResultSelector from "./result-selector";
import HistoryTable from "./history-table";
import HistoryUserSearch from "./user-search";

interface Props {
  problemTitle: string;
}

export type SubmitHistoryFormType = {
  user: string;
  language: string;
  result: string;
};

export const ProblemDetailSubmitHistory = ({ problemTitle }: Props) => {
  const [searchParams] = useSearchParams();
  const isMySubmitPath = searchParams.get("nav") === "my_submits";

  const methods = useForm<SubmitHistoryFormType>({
    defaultValues: {
      user: "",
      language: "모든 언어",
      result: "모든 결과",
    },
  });

  const onHistorySearch = (data: SubmitHistoryFormType) => {
    const { user, language, result } = data;

    console.log(LANGUAGE_OPTION_LIST[language as LanguageOptionType]);
  };

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset();
  }, [searchParams]);

  return (
    <section className="flex flex-col gap-40 px-20 mx-auto mt-40 max-w-1200">
      <h1 className="font-medium text-20">{problemTitle}</h1>
      <div className="flex flex-col gap-20">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onHistorySearch)}
            className="flex gap-12"
          >
            {!isMySubmitPath && <HistoryUserSearch />}
            <HistoryLanguageSelector />
            <HistoryResultSelector />
            <Button
              type="submit"
              className="rounded-[4px] px-14 text-14 font-medium bg-blue-500 text-white"
            >
              검색
            </Button>
          </form>
        </FormProvider>
        <HistoryTable submitHistoryInfo={DUMMY_SUBMIT_HISTORY} />
      </div>
    </section>
  );
};
