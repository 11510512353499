export const MainBanner = () => {
  return (
    <section
      className="!bg-no-repeat w-full h-360 bg-cover bg-center"
      style={{
        backgroundImage: "url('/assets/main/banner-01.jpg')",
      }}
    >
      <div className="max-w-1200 mx-auto px-20 flex flex-col justify-center h-full gap-20 under:drop-shadow-[1px_1px_2px_rgba(0,0,0,0.6)]">
        <p className="text-40 font-semibold text-white leading-[1.3] ">
          당신의 실력이 곧 대한민국의
          <br /> 디지털 수사능력이 됩니다.
        </p>
        <p className="text-24 font-medium text-white leading-[1.45]">
          대한민국 디지털 포렌식 1위 기업,
          <br /> GMDSOFT 채용 CTF에 도전하세요!
        </p>
      </div>
    </section>
  );
};
