import { DropDown } from "components/shared";
import { LANGUAGE_OPTION_LIST } from "variables/problem";
import { useFormContext } from "react-hook-form";
import { SubmitHistoryFormType } from "..";

const HistoryLanguageSelector = () => {
  const { watch, setValue } = useFormContext<SubmitHistoryFormType>();

  return (
    <div className="w-150">
      <DropDown
        optionList={Object.keys(LANGUAGE_OPTION_LIST)}
        selectedOption={watch("language")}
        setSelectedOption={(lang) => setValue("language", lang)}
      />
    </div>
  );
};

export default HistoryLanguageSelector;
