import { type DUMMY_PROBLEM_DETAIL } from "dummy-data";

interface Props {
  contentExplain: (typeof DUMMY_PROBLEM_DETAIL)["explain"];
}

const DetailContentExplain = ({ contentExplain }: Props) => {
  return (
    <div className="flex flex-col gap-20">
      {Object.entries(contentExplain).map(([key, val], idx) => {
        return (
          <article key={idx} className="">
            <h2 className="pb-12 font-medium border-b-2 text-22">
              {key === "problem" && "문제"}
              {key === "input" && "입력"}
              {key === "output" && "출력"}
            </h2>
            <p className="py-20 font-medium">{val}</p>
          </article>
        );
      })}
    </div>
  );
};

export default DetailContentExplain;
