import { useFormContext, Controller } from "react-hook-form";
import { type ProblemSubmitFormType } from "..";
import { Editor } from "@monaco-editor/react";

const SubmitCodeArea = () => {
  const { control, watch } = useFormContext<ProblemSubmitFormType>();
  const selectedLang = watch("language");

  return (
    <div className="flex flex-col gap-10 mt-40">
      <label htmlFor="source-code" className="font-medium text-15">
        소스 코드
      </label>
      <Controller
        name="sourceCode"
        control={control}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          return (
            <Editor
              height={"60vh"}
              language={selectedLang.toLowerCase()}
              value={value}
              onChange={(val) => onChange(val)}
              options={{
                minimap: { enabled: false },
                fontSize: 15,
                padding: { top: 5, bottom: 5 },

                scrollbar: {
                  alwaysConsumeMouseWheel: false,
                },
              }}
              className="border-1 rounded-[4px]"
            />
          );
        }}
      />
    </div>
  );
};

export default SubmitCodeArea;
