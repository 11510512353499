import { useSearchParams } from "react-router-dom";
import {
  ProblemDetailNav,
  ProblemDetailInfo,
  ProblemDetailSubmit,
  ProblemDetailSubmitHistory,
} from "components/problem-detail";
import { DUMMY_PROBLEM_DETAIL } from "dummy-data";

const ProblemDetail = () => {
  const [searchParams] = useSearchParams();
  const currParam = searchParams.get("nav");

  return (
    <div>
      <ProblemDetailNav />
      {!currParam && <ProblemDetailInfo problemInfo={DUMMY_PROBLEM_DETAIL} />}
      {currParam === "submit" && (
        <ProblemDetailSubmit problemTitle={DUMMY_PROBLEM_DETAIL.title} />
      )}
      {(currParam === "my_submits" || currParam === "all_submits") && (
        <ProblemDetailSubmitHistory problemTitle={DUMMY_PROBLEM_DETAIL.title} />
      )}
    </div>
  );
};

export default ProblemDetail;
