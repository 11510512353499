import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { useState } from "react";
import NotFound from "pages/404";
import Layout from "components/layout";
import Main from "pages/main";
import Problem, { loader as problemLoader } from "pages/problem";
import ProblemDetail from "pages/problem-detail";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 2,
          },
        },
      })
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <Main />,
        },
        {
          path: "problem",
          children: [
            {
              index: true,
              element: <Problem />,
              loader: ({ request }) => problemLoader(request, queryClient),
            },
            {
              path: ":id",
              element: <ProblemDetail />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
