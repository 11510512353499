import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../button";
import { SpriteIcon } from "../icon";
import ClickAwayListener from "react-click-away-listener";
import { type SORT_OPTIONS } from "components/problem/content/menu";
import clsx from "clsx";

interface Props {
  sortOptions: typeof SORT_OPTIONS;
}

export const PageSortFilter = ({ sortOptions }: Props) => {
  const [isDropDown, setIsDropDown] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get("sort") ?? sortOptions[0].query;

  const handleDropDownClick = () => {
    if (!isDropDown) setIsDropDown(true);
    else setIsDropDown(false);
  };

  const handleOptionClick = (query: string) => {
    setSearchParams((param) => {
      param.set("sort", query);
      return param;
    });
  };

  return (
    <div className="relative select-none">
      <ClickAwayListener onClickAway={() => setIsDropDown(false)}>
        <Button
          onClick={handleDropDownClick}
          className="gap-8 font-medium flex-center text-15"
        >
          <span>
            {sortOptions.map(({ name, query }) => {
              return param === query && name;
            })}
          </span>
          <SpriteIcon
            size={16}
            icon="chevron-down"
            className={clsx("transition-transform", isDropDown && "rotate-180")}
          />
        </Button>
      </ClickAwayListener>
      {isDropDown && (
        <div className="absolute right-0 bg-white top-30 w-150 border-1 border-blue-500 rounded-[4px]">
          <ul className="flex flex-col text-left">
            {sortOptions.map(({ name, query }, idx) => {
              return (
                <li
                  key={idx}
                  className="px-10 py-12 font-medium cursor-pointer hover:bg-blue-500 hover:text-white text-15"
                  onClick={() => handleOptionClick(query)}
                >
                  {name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
