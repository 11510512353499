import { DropDown } from "components/shared";
import { useFormContext } from "react-hook-form";
import { type ProblemSubmitFormType } from "..";
import { LANGUAGE_OPTION_LIST } from "variables/problem";

const SubmitLanguageSelector = () => {
  const { watch, setValue } = useFormContext<ProblemSubmitFormType>();

  return (
    <div className="flex flex-col w-full gap-10 select-none max-w-140">
      <label className="font-medium text-15">언어 선택</label>
      <DropDown
        optionList={LANGUAGE_OPTIONS}
        selectedOption={watch("language")}
        setSelectedOption={(lang) => setValue("language", lang)}
      />
    </div>
  );
};

export default SubmitLanguageSelector;

const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_OPTION_LIST);
LANGUAGE_OPTIONS.shift();
