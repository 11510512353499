// id: number;
// name: string;
// description: {
//   type: string;
//   title: string;
//   content: string;
// }[];
// maxCpuTime: number;
// maxMemory: number;
// submission: number;
// accepted: number;
// languages: Language[];

export const DUMMY_PROBLEM_ITEMS = [
  {
    id: 1000,
    name: "서버 뒤진 문제 1호",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 200,
    createdAt: "2020",
  },
  {
    id: 2000,
    name: "서버 뒤진 문제 2호",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 200,
    createdAt: "2020",
  },
  {
    id: 3000,
    name: "서버 뒤진 문제 3호",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 200,
    createdAt: "2020",
  },
  {
    id: 4000,
    name: "서버 뒤진 문제 4호",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 200,
    createdAt: "2020",
  },
  {
    id: 5000,
    name: "서버 뒤진 문제 5호",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 200,
    createdAt: "2020",
  },
];

export const BEGINNER_PROBLEMS = [
  {
    id: 1000,
    name: "초심자 문제1",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "beginner",
    createdAt: "2020",
  },
  {
    id: 1002,
    name: "초심자 문제2",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "beginner",
    createdAt: "2020",
  },
  {
    id: 1003,
    name: "초심자 문제3",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "beginner",
    createdAt: "2020",
  },
  {
    id: 1004,
    name: "초심자 문제4",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "beginner",
    createdAt: "2020",
  },
  {
    id: 1005,
    name: "초심자 문제5",
    level: 1,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "beginner",
    createdAt: "2020",
  },
];

export const RECOMMENDED_PROBLEMS = [
  {
    id: 2001,
    name: "추천 문제1",
    level: 2,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "recommend",
    createdAt: "2020",
  },
  {
    id: 2002,
    name: "추천 문제2",
    level: 2,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "recommend",
    createdAt: "2020",
  },
  {
    id: 2003,
    name: "추천 문제3",
    level: 2,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "recommend",
    createdAt: "2020",
  },
  {
    id: 2004,
    name: "추천 문제4",
    level: 2,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "recommend",
    createdAt: "2020",
  },
  {
    id: 2005,
    name: "추천 문제5",
    level: 2,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "recommend",
    createdAt: "2020",
  },
];

export const NEW_PROBLEMS = [
  {
    id: 3001,
    name: "최신 문제1",
    level: 3,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "new",
    createdAt: "2020",
  },
  {
    id: 3002,
    name: "최신 문제2",
    level: 3,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "new",
    createdAt: "2020",
  },
  {
    id: 3003,
    name: "최신 문제3",
    level: 3,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "new",
    createdAt: "2020",
  },
  {
    id: 3004,
    name: "최신 문제4",
    level: 3,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "new",
    createdAt: "2020",
  },
  {
    id: 3005,
    name: "최신 문제5",
    level: 3,
    acceptedCount: 100,
    acceptedRate: 50,
    submissionCount: 130,
    category: "new",
    createdAt: "2020",
  },
];

export const DUMMY_PROBLEM_DETAIL = {
  title: "해병 + 햄버거",
  explain: {
    problem:
      "두 정수 해병과 햄버거를 입력받은 다음, 해병 + 햄버거를 출력하는 프로그램을 작성하시오.",
    input: "첫째 줄에 해병과 햄버거가 주어진다. (0 < 해병, 햄버거 < 10)",
    output: "첫째 줄에 해병 + 햄버거를 출력한다.",
  },
  examples: [
    { input: "싱글라인 입력 1 2", output: "싱글라인 출력 1 2" },
    {
      input: "멀티라인 입력 1\n멀티라인 입력 2",
      output: "멀티라인 출력 1\n멀티라인 출력 2",
    },
  ],
};

export const DUMMY_SUBMIT_HISTORY = [
  {
    id: 1234,
    userName: "rrrrmaster",
    resultId: 1,
    memory: 2000,
    time: 36,
    codeLength: 400,
    languageId: 8,
    ago: 15,
  },
  {
    id: 12345,
    userName: "rrrrmaster",
    resultId: 2,
    memory: 800,
    time: 36,
    codeLength: 80,
    languageId: 4,
    ago: 15,
  },
  {
    id: 123456,
    userName: "rrrrmaster",
    resultId: 3,
    memory: 1200,
    time: 86,
    codeLength: 400,
    languageId: 5,
    ago: 15,
  },
  {
    id: 1234568,
    userName: "rrrrmaster",
    resultId: 4,
    memory: 500,
    time: 200,
    codeLength: 76,
    languageId: 3,
    ago: 15,
  },
];
