import { DropDown } from "components/shared";
import { RESULT_OPTION_LIST } from "variables/problem";
import { useFormContext } from "react-hook-form";
import { SubmitHistoryFormType } from "..";

const HistoryResultSelector = () => {
  const { setValue, watch } = useFormContext<SubmitHistoryFormType>();

  return (
    <div className="w-150">
      <DropDown
        optionList={Object.keys(RESULT_OPTION_LIST)}
        selectedOption={watch("result")}
        setSelectedOption={(option) => setValue("result", option)}
      />
    </div>
  );
};

export default HistoryResultSelector;
