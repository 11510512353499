import { useForm, FormProvider } from "react-hook-form";
import SubmitLanguageSelector from "./language-selector";
import SubmitShareSource from "./share-source";
import SubmitCodeArea from "./code-area";
import { Button } from "components/shared";
import { LANGUAGE_OPTION_LIST, LanguageOptionType } from "variables/problem";

interface Props {
  problemTitle: string;
}

export type ProblemSubmitFormType = {
  language: string;
  share: string;
  sourceCode: string;
};

export const ProblemDetailSubmit = ({ problemTitle }: Props) => {
  const methods = useForm<ProblemSubmitFormType>({
    defaultValues: {
      language: "C",
      share: "공개",
      sourceCode: "",
    },
  });

  const { handleSubmit } = methods;

  const onSubmitClick = (data: ProblemSubmitFormType) => {
    const { language, share, sourceCode } = data;

    console.log(LANGUAGE_OPTION_LIST[language as LanguageOptionType]);
  };

  return (
    <section className="flex flex-col gap-40 px-20 mx-auto mt-40 max-w-1200">
      <h1 className="font-medium text-20">{problemTitle}</h1>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitClick)}>
          <div className="flex items-center w-full gap-20">
            <SubmitLanguageSelector />
            <SubmitShareSource />
          </div>
          <SubmitCodeArea />
          <div className="flex justify-end w-full">
            <Button
              type="submit"
              className="px-20 py-10 mt-20 text-white bg-blue-500 rounded-sm text-14"
            >
              제출
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
