import { baseAPI } from "api/core";
import { throwRemoteError } from "api/error";

export const problemServiceRemote = () => ({
  problemList: async (pageNum: number, filters: ProblemListFilters) => {
    try {
      const response = await baseAPI.get("/problem", {
        params: {
          page: pageNum,
          ...filters,
        },
      });
      return response.data;
    } catch (error) {
      throwRemoteError(error);
    }
  },
  problemDetail: async (id: number) => {
    try {
      const response = await baseAPI.get(`/problem/${id}`);
      return response.data;
    } catch (error) {
      throwRemoteError(error);
    }
  },
  problemSubmit: async (
    problemId: number,
    submitData: ProblemSubmitRequest
  ) => {
    try {
      const response = await baseAPI.post("/submission", {
        data: {
          source_code: submitData.sourceCode,
          problem_id: problemId,
          language_id: submitData.languageId,
        },
      });
      return response.data;
    } catch (error) {
      throwRemoteError(error);
    }
  },
});
